import { Controller } from "@hotwired/stimulus"

class PlaceAutocompleteController extends Controller {
  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.element, {
      language: 'en-GB',
      componentRestrictions: {
        country: "uk"
      },
      fields: ['name'],
    });
  }
}

export default PlaceAutocompleteController;