import { Controller } from "@hotwired/stimulus"
import NeoDialog from "src/NeoDialog";

export default class BookButtonController extends Controller {
  connect() {
    const dataset = this.element.dataset;

    this.userType = dataset.userType;
    this.primaryName = dataset.primaryName;
    this.primaryEmail = dataset.primaryEmail;
    this.facilityId = dataset.facilityId;
    this.facilityCategoryId = dataset.facilityCategoryId;
    this.url = dataset.url;

    if (dataset.autoClick === 'true') this.action();
  }

  click(event) {
    event.preventDefault();
    this.action();
  }

  action() {
    if (this.userType === 'secondary') {
      const message = "This account is a secondary account linked to " + this.primaryName + " - " + this.primaryEmail +
        ". It is not possible to make bookings or send messages as a secondary account.";

      NeoDialog.confirm(message, 'Ok', null, false)
    } else {
      const eventDate = document.getElementById('booking_date').value;

      const { location } = window;
      const { host, protocol } = location;

      const params = new URLSearchParams({
        event_date: eventDate,
        facility_category_id: this.facilityCategoryId,
        facility_id: this.facilityId
      });

      window.location = `${protocol}//${host}${this.url}?${params.toString()}`;
    }
  }
}
