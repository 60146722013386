import React, { useEffect, useState, useRef, useMemo } from "react";
import Calendar from "./Calendar";
import Day from "./Day";
import Facility from "./Facility";
import EventDate from "./EventDate";
import Periods from "../BookingForm/Block/Periods";
import BookingOption from "./BookingOption";
import VatCheckbox from "./VatCheckbox";
import Extras from "./ExtrasDropdown";
import PriceAdjustmentReason from "./PriceAdjustmentReason";
import PriceAdjustment from "./PriceAdjustment";
import Error from "./Error";
import api from "./api";
import TimeSlots from "./TimeSlots";
import Modal from "./Modal";

import { reassignStartAndEndTime } from './utils/startAndEndTime';
import { processingModal } from "src/nextgen/helpers/processing_modal";

import { DateTime } from "luxon";
import axios from 'axios';

import './styles.scss';

export default ({ url, multiple }) => {
  const ref = useRef();
  const [loading, setLoading] = useState('pending');

  const [facilities, setFacilities] = useState([]);
  const [bookingOptions, setBookingOptions] = useState([]);
  const [extras, setExtras] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [minEventDate, setMinEventDate] = useState(null);
  const [maxEventDate, setMaxEventDate] = useState(null);

  const [bookingId, setBookingId] = useState('');
  const [userId, setUserId] = useState(null);
  const [eventDate, setEventDate] = useState(new Date);
  const [facilityKey, setFacilityKey] = useState(null);
  const [bookingOptionKey, setBookingOptionKey] = useState(null);
  const [vatEnabled, setVatEnabled] = useState(false);
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null);
  const [day, setDay] = useState(null)
  const [bookingsExtras, setBookingsExtras] = useState([]);
  const [priceAdjustment, setPriceAdjustment] = useState(0);
  const [priceAdjustmentReason, setPriceAdjustmentReason] = useState('');
  const [showPriceAdjustment, setShowPriceAdjustment] = useState(false);
  const [showVatEnabled, setShowVatEnabled] = useState(false);
  const [internalOrderUuid, setInternalOrderUuid] = useState(null);
  const [errors, setErrors] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const continueButtonDisabled = useMemo(() => {
    const values = [facilityKey, eventDate, startTime, endTime, bookingOptionKey];
    const isNull = (element) => element === null;
    return values.some(isNull) || inProgress;
  }, [facilityKey, eventDate, startTime, endTime, bookingOptionKey, inProgress])

  useEffect(() => {
    api.bookingPromise(url).then(({ data }) => {
      const newStartTime = data.start_time;
      const newEndTime = data.end_time;
      const newEventDate = data.event_date;

      const luxonEventDate = DateTime.fromFormat(newEventDate, 'yyyy-MM-dd');
      const n = luxonEventDate.toJSDate();
      const day = luxonEventDate.weekday;

      setBookingId(data.id);
      setUserId(data.user?.id);
      setEventDate(n);
      setDay(day);
      setFacilityKey(data.facility_key);
      setBookingOptionKey(data.booking_option_key);
      setVatEnabled(data.vat_enabled);
      setStartTime(data.start_time);
      setEndTime(data.end_time);
      setBookingsExtras(data.bookings_extras);
      setPriceAdjustment(data.price_adjustment);
      setPriceAdjustmentReason(data.price_adjustment_reason || '');
      setMinEventDate(data.min_event_date);
      setMaxEventDate(data.max_event_date);
      setShowPriceAdjustment(data.show_price_adjustment);
      setShowVatEnabled(data.show_vat_enabled);
      setInternalOrderUuid(data.order.internal_order_uuid);

      api.venuePromise(data.venue_id, data.id, data.user?.id).then((response) => {
        setFacilities(response.data.facilities)

        api.fetchFacility(data.facility_key, data.user?.id, data.id).then((response2) => {
          setBookingOptions(response2.data.booking_options);
          setExtras(response2.data.extras);

          const newBookingOptionKey = data.booking_option_key;
          const newBookingId = data.id;
          const newFacilityKey = data.facility_key;
          const newInternalOrderUuid = data.order.internal_order_uuid;

          api
            .fetchPeriods(newBookingOptionKey, newEventDate, newBookingId, newFacilityKey, newInternalOrderUuid)
            .then((response3) => {
              const newPeriod = reassignStartAndEndTime(response3.data, { startTime: newStartTime, endTime: newEndTime })
              setPeriods(response3.data);
              setStartTime(newPeriod.startTime);
              setEndTime(newPeriod.endTime);
              setLoading('succeeded');
              $(ref.current).modal('show');
          }).catch((e) => failedLoading(e));
        }).catch((e) => failedLoading(e));
      }).catch((e) => failedLoading(e));
    }).catch((e) => failedLoading(e));
  }, []);

  const failedLoading = (error) => {
    Helper.flash_message('error', 'Something went wrong!')
    setLoading('failed');
  }

  const handleChangeFacility = function(e) {
    const newFacilityKey = e.target.value;

    setFacilityKey(newFacilityKey);

    api.fetchFacility(newFacilityKey, userId, bookingId).then((response2) => {
      const newExtras = response2.data.extras;

      let newBookingsExtras = [...bookingsExtras];

      newExtras.filter(extra => extra.checked).map(extra => {
        const bookingsExtra = bookingsExtras.find((bookingsExtra) => bookingsExtra.extra_id === extra.id);

        if (bookingsExtra === undefined) {
          newBookingsExtras.push({ id: '', extra_id: extra.id, persons: '', checked: true })
        }
      });

      const bok = (response2.data.booking_options[0]?.id || null);

      setBookingOptions(response2.data.booking_options);
      setExtras(newExtras);
      setBookingsExtras(newBookingsExtras)
      setBookingOptionKey(bok)

      if (bok) {
        loadPeriods(eventDate, bok, newFacilityKey);
      } else {
        console.log('The list of booking options is empty for this facility');
      }
    });
  }

  const handleChangeBookingOption = function(e) {
    const newBookingOptionKey = e.target.value;
    setBookingOptionKey(newBookingOptionKey);
    loadPeriods(eventDate, newBookingOptionKey, facilityKey);
  }

  const handleChangeEventDate = function(newEventDate) {
    setEventDate(newEventDate);
    loadPeriods(newEventDate, bookingOptionKey, facilityKey);
  }

  function loadPeriods(eventDate, bookingOptionKey, facilityKey) {
    api.fetchPeriods(bookingOptionKey, eventDate, bookingId, facilityKey, internalOrderUuid).then((response) => {
      const newPeriod = reassignStartAndEndTime(response.data, { startTime: startTime, endTime: endTime })
      setPeriods(response.data);
      setStartTime(newPeriod.startTime);
      setEndTime(newPeriod.endTime);
    }).catch((e) => console.log(e));
  }

  const handleChangeVat = (e) => setVatEnabled(e.target.checked);
  const handleChangeStartTime = (time) => setStartTime(time);
  const handleChangeEndTime = (time) => setEndTime(time);
  const handleChangePriceAdjustment = (e) => setPriceAdjustment(e.target.value);
  const handleChangePriceAdjustmentReason = (e) => setPriceAdjustmentReason(e.target.value);

  const handleChangeDay = (day) => setDay(day);

  const sendForm = (e) => {
    e.preventDefault;

    if (continueButtonDisabled) return true;

    setInProgress(true);
    processingModal.show();

    const extraIds = extras.map(x => x.id);
    const formattedEventDate = DateTime.fromJSDate(eventDate).toFormat("yyyy-MM-dd");

    const data = {
      booking: {
        facility_key: facilityKey,
        event_date: formattedEventDate,
        start_datetime: [formattedEventDate, startTime].join(' '),
        end_datetime: [formattedEventDate, endTime].join(' '),
        booking_option_key: bookingOptionKey,
        bookings_extras_attributes: bookingsExtras.map(bookings_extra => {
          let destroy = false;

          if (!extraIds.includes(bookings_extra.extra_id)) {
            destroy = true;
          } else if (bookings_extra.checked === undefined) {
            destroy = false;
          } else {
            destroy = !bookings_extra.checked;
          }

          return {
            id: bookings_extra.id,
            extra_id: bookings_extra.extra_id,
            persons_number: bookings_extra.persons,
            price_adjustment: bookings_extra.price_adjustment,
            price_adjustment_reason: bookings_extra.price_adjustment_reason,
            _destroy: destroy
          }
        }),
        price_adjustment: priceAdjustment,
        price_adjustment_reason: priceAdjustmentReason
      }
    };

    if (multiple) {
      data['booking']['day'] = day;
      data['booking_ids'] = $.map($('.multiple-check:checked'), (row) => row.value);
      data['booking']['admin_vat_enabled'] = vatEnabled;
    }

    axios.put(url.replace('/edit', ''), data).then(() => {
      const reloadUrl = $('#bookings-block .table-bookings').data('reload-url');

      axios.get(reloadUrl).then((response) => {
        window.bookingTable.update(response.data);
        processingModal.hide();
        $(ref.current).modal('hide');
        setTimeout(() => setInProgress(false), 300);
      }).catch((e) => {
        processingModal.hide();
        setTimeout(() => setInProgress(false), 300);
      });
    }).catch((error) => {
      processingModal.hide();
      setInProgress(false);
      setErrors(error.response.data.errors)
    })
  }

  const title = multiple ? 'Edit multiple bookings' : 'Edit booking time';

  return (
    <Modal ref={ref} title={title} loading={loading} onSendForm={sendForm}
           continueButtonDisabled={continueButtonDisabled}>
      <div className='booking-form'>
        {!multiple &&
          <div className='row'>
            <div className='col col-xs-12 col-sm-6 col-md-6 col-lg-6'>
              <Calendar
                eventDate={eventDate}
                minEventDate={minEventDate}
                maxEventDate={maxEventDate}
                onChange={handleChangeEventDate}
              />
            </div>
            <div className='col col-xs-12 col-sm-6 col-md-6 col-lg-6'>
              <TimeSlots
                periods={periods}
                startTime={startTime}
                endTime={endTime}
                onChangeStartTime={handleChangeStartTime}
                onChangeEndTime={handleChangeEndTime}
              />
            </div>
          </div>
        }

        <div className='row-container new-book'>
          <div className='fields-container'>
            <div className="field-box facility-box">
              <Facility
                value={facilityKey}
                facilities={facilities}
                onChange={handleChangeFacility}
              />
            </div>

            {!multiple &&
              <div className="field-box event-date-box">
                <EventDate
                  eventDate={eventDate}
                  minEventDate={minEventDate}
                  maxEventDate={maxEventDate}
                  onChange={handleChangeEventDate}
                />
              </div>
            }

            <div className="field-box period-wrapper">
              <div className="field-box periods-box">
                <Periods
                  periods={periods}
                  startTime={startTime}
                  endTime={endTime}
                  onChangeStartTime={handleChangeStartTime}
                  onChangeEndTime={handleChangeEndTime}
                />
              </div>

              {multiple &&
                <div className="field-box day-box">
                  <Day day={day} onChangeDay={handleChangeDay}/>
                </div>
              }
            </div>

          </div>
        </div>

        <div className='row-container new-book'>
          <div className='fields-container'>
            <div className='field-box booking-option-box'>
              <BookingOption
                bookingOptions={bookingOptions}
                value={bookingOptionKey}
                onChange={handleChangeBookingOption}
              />
            </div>

            {multiple && showVatEnabled &&
              <div className='field-box vat-box'>
                <VatCheckbox checked={vatEnabled} onChange={handleChangeVat}/>
              </div>
            }
          </div>
        </div>

        {showPriceAdjustment &&
          <div className='row-container new-book'>
            <div className='fields-container'>
              <div className='field-box price-adjustment-box'>
                <PriceAdjustment
                  value={priceAdjustment}
                  onChange={handleChangePriceAdjustment}
                />
              </div>

              <div className='field-box price-adjustment-reason-box'>
                <PriceAdjustmentReason
                  value={priceAdjustmentReason}
                  onChange={handleChangePriceAdjustmentReason}
                />
              </div>
            </div>
          </div>
        }


        <div className='row'>
          <div className='col-lg-12'>
            <div className='new-book edit-booking-form'>
              <div className='form-inline vert-offset-bottom-10'>
                <Extras
                  extras={extras}
                  bookingsExtras={bookingsExtras}
                  setBookingsExtras={setBookingsExtras}
                  showPriceAdjustment={showPriceAdjustment}
                />
              </div>

              <Error errors={errors}/>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
