import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

class CustomMessageSignatureController extends Controller {
  static targets = ['body', 'enabledSignature'];

  connect() {
    this.isModalLoading = false;
  }

  showModal(e) {
    e.preventDefault();

    if (this.isModalLoading) return;

    this.isModalLoading = true

    const url = e.target.dataset.url;
    const submenuElement = document.querySelector('.maintabs_submenu');
    const modal = document.getElementById('modal_form');
    const content = modal.querySelector('.modal-content');
    const venueId = submenuElement?.dataset?.venue_id;

    axios
      .get(url, { params: { venue_id: venueId }})
      .then((response) => {
        content.innerHTML = response.data;
        $(modal).modal('show');
        this.isModalLoading = false;
      });
  }

  selectVenue(e) {
    const target = e.target;
    const url = `${target.dataset.url}&venue_id=${target.value}`;
    const body = this.bodyTarget;
    const enabledSignatureCheckbox = this.enabledSignatureTarget;

    if (target.value === 'all-venues') {
      body.value = '';
      enabledSignatureCheckbox.checked = false

      return null;
    }

    axios.get(url).then((response) => {
      body.value = response.data.body;
      enabledSignatureCheckbox.checked = response.data.enabled;
    });
  }
}

export default CustomMessageSignatureController;
