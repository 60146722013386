import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const el = $('#inline-datepicker');

    el.datepicker({
      orientation: "bottom auto",
      maxViewMode: "days",
      format: 'dd/mm/yyyy',
      weekStart: 1
    });

    el.on('changeDate', () => {
      $('#booking_date').val(el.datepicker('getFormattedDate'))
    });
  }
}
