import { getBookingInfo } from "../Modal";

const DayCalendar = (function() {
  function show(options, refreshCalendar) {
    const { viewDate, minTime, maxTime, facilityData, bookingData } = options;

    var $table = $('.fc-view table');
    var $calendar = $('#calendar');

    $table.floatThead('destroy');
    $calendar.fullCalendar('destroy');

    $calendar.html('');

    $calendar.fullCalendar({
      defaultView: 'agendaDay',
      defaultDate: viewDate,
      editable: false,
      allDaySlot: false,
      selectable: false,
      firstDay: 1,
      minTime: minTime,
      maxTime: maxTime,
      slotLabelFormat: 'HH:mm',
      defaultTimedEventDuration: '00:30:00',
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      allDay: false,
      resourceGroupField: 'title',
      eventLimit: true,
      views: {
        week: {
          titleFormat: 'dddd, DD/MM',
          columnFormat: 'dddd, DD/MM'
        }
      },
      timeFormat: 'H:mm',
      header: {
        left: null,
        center: null,
        right: null
      },
      eventClick: (calEvent) => getBookingInfo(calEvent.id, refreshCalendar),
      resources: facilityData,
      events: bookingData
    });

    rebuildCalendar();
  }

  function rebuildCalendar() {
    $('th.fc-axis').html('Time slot');
    $('#calendar').fullCalendar('option', 'height', 'auto');
    $('.block-booked').prepend('<div class="legend-block legend-no-margins legend-triangle"></div>');
    AlignText();
    MakeLinks();
    prettifyAxis();
    $('.fc-view colgroup').remove();
    $($('.fc-agenda-view table')[0]).floatThead({ position: 'absolute', autoReflow: true });
  }

  function MakeLinks() {
    $.each($('.fc-resource-cell'), function(ind, label) {
      $(label).html($(label).text());
    });
  }

  function AlignText() {
    $.each($('.booking-item'), function(ind, item) {
      let height = $(item).height();
      return $(item).find('.fc-content').css('padding-top', height / 2 - 10);
    });
  }

  function prettifyAxis() {
    var collection = $('.fc-slats tr');

    $.each(collection, function(index, item) {
      var displaytime, parsed_time, time;
      time = $(item).data('time');
      displaytime = new moment(time, 'HH:mm');
      parsed_time = new moment(time, 'HH:mm').add(30, 'minutes');
      parsed_time.format('HH:mm');

      $(item).find('.fc-time').html('<span class="axis-time-range">' +
        displaytime.format('HH:mm') +
        ' - ' +
        parsed_time.format('HH:mm') +
        '</span>');
    });
  }

  return {
    show: show
  };
})();

const AgendaDay = (function() {
  function show(options, refreshCalendar) {
    let { headerWidth, facilityData} = options;
    const calendarNoteHTML = atob(options.base64CalendarNoteHTML);
    if (calendarNoteHTML.length > 0) $('#dayCalendarNote').html(calendarNoteHTML);
    $('#calendar').show()
    DayCalendar.show(options, refreshCalendar);

    const amount = facilityData.length;
    const tableWidth = valueToPx(amount * 280 > 1300 ? amount * 280 : 1300);

    var table = $('#calendar .fc-view > table');

    table.css('width', valueToPx(amount * 280));
    $('th.fc-axis').attr('style', "width: " + valueToPx(headerWidth) + "!important");
    $('.floatThead-container').css('width', valueToPx(headerWidth * 280) + "px");

    table.on("floatThead", function(e, isFloated) {
      if(isFloated){
        $('#calendar .fc-view > table').css('width', tableWidth)
        $('.floatThead-container').css('width', tableWidth)
        $('.floatThead-table').css('width', tableWidth)
      } else {
        $('#calendar .fc-view > table').css('width', tableWidth)
        $('.floatThead-container').css('width', tableWidth)
        $('.floatThead-table').css('width', tableWidth)
      }
    });

    $('.fc-axis.fc-time.fc-widget-content').css('background-color', '#FFFFFF')
    $('.fc-time-grid-container').height(valueToPx($('.fc-time-grid-container').height() + 2))
    $('#calendar_top_scroll_content').attr('style', 'width: ' + valueToPx(table.width()));
  }

  function valueToPx(value) {
    return value.toString() + "px";
  }

  return {
    show: show
  };
})();

export default AgendaDay;