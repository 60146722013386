import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.selectTarget.addEventListener("change", this.onChange.bind(this));
  }

  onChange(event) {
    const target = event.target;
    const form = target.closest("form");
    const url = form.getAttribute("action");

    axios
      .put(url, { related_team_id: target.value })
      .catch((error) => {
        Helper.flash_message("error", error.response.data.errors);
      });
  }
}
