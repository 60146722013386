import React, {useState, useRef, useEffect} from "react";
import moment from "moment/moment";
import Calendar from "src/components/MegaFilter/TimePeriods/Calendar";
import {Popover} from "react-tiny-popover";

function isDateInMonth(dateValue, dateMonth) {
  // Ensure the inputs are Date objects
  const givenDate = new Date(dateValue);
  const monthToCheck = new Date(dateMonth);

  // Calculate the start and end of the month
  const startOfMonth = new Date(monthToCheck.getFullYear(), monthToCheck.getMonth(), 1);
  const endOfMonth = new Date(monthToCheck.getFullYear(), monthToCheck.getMonth() + 1, 0);

  // Check if the given date is within the start and end of the month
  return givenDate >= startOfMonth && givenDate <= endOfMonth;
}

export default ({ value, view, minDate, maxDate, onChange, disabled, className }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [dateMonth, setDateMonth] = useState(moment(value).toDate());

  const ref = useRef();

  useEffect(() => {
    if (!isDateInMonth(value, dateMonth)) setDateMonth(value);
  }, [value])

  const handleChangeValue = (value) => {
    onChange(moment(value).toDate());
  }

  if (view === 'block') {
    return <Calendar
      className={className}
      dateMonth={dateMonth}
      minDate={moment(minDate)}
      maxDate={moment(maxDate)}
      setDateMonth={setDateMonth}
      onDayClick={(d) => handleChangeValue(d)}
      selectedRange={value}
    />
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      align={'center'}
      disableReposition={true}
      onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
      ref={ref}
      padding={6}
      content={(
        <div className="calendar-block" style={{ background: '#fff', border: '1px solid #dbdbdb' }}>
          <Calendar
            className={className}
            dateMonth={dateMonth}
            minDate={moment(minDate)}
            maxDate={moment(maxDate)}
            setDateMonth={setDateMonth}
            onDayClick={(d) => { handleChangeValue(d); setIsPopoverOpen(false) }}
            selectedRange={value}
          />
        </div>
      )}
    >
      <div style={{position: 'relative'}}>
        <input type='text'
               ref={ref}
               readOnly={true}
               onClick={() => setIsPopoverOpen(true)}
               disabled={disabled}
               value={moment(value).format('ddd, DD MMM YYYY')}
               className='string optional form-control'/>
      </div>
    </Popover>
  );
}