import axios from "axios";
import moment from "moment/moment";

class Api {
  bookingPromise(url) {
    return axios({ method: 'GET', url: url });
  }

  venuePromise(venueId, id, userId) {
    const url = `venues/${venueId}/facilities?booking_id=${id}&customer_id=${userId}`;
    const httpParams = { method: 'GET', url: url };
    return axios(httpParams)
  }

  fetchPeriods(bookingOptionKey, eventDate, bookingId, facilityKey, internalOrderUuid) {
    const url = `/booking_options/${bookingOptionKey}'/list`;

    const params = {
      event_date: moment(eventDate).format('D.M.YYYY'),
      except_booking_id: bookingId,
      facility_key: facilityKey,
      internal_order_uuid: internalOrderUuid,
      notice_page: 'edit_booking'
    }

    return axios({ method: 'GET', url: url, params: params });
  }

  fetchFacility(facilityKey, userId, bookingId) {
    const url = `facilities/${facilityKey}/dropdowns`;
    const params = { user_id: userId, booking_id: bookingId };

    return axios({ method: 'GET', url: url, params: params });
  }
}

export default new Api;