import React, { useState, useMemo, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { useFetch } from "../Api/UseFetch";
import DropDown from "./DropDown";

export default ({ item, options, target, onChanged }) => {
  const { venues, venue, setVenue, pending } = useVenues(item, options, target);

  useEffect(() => {
    const params = venue ? { venueId: venue.id } : null;
    onChanged({ value: venue, pending, params })
  }, [venue, pending]);

  return (
    <div className={classNames(['filter-block filter-venues'])}>
      <label htmlFor="filter-venues">Venue:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={venue}
          onChange={setVenue}
          options={venues}
          isDisabled={pending}
          components={{ SingleValue, Option }}
          isLoading={pending}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.id}
        />
      </div>
    </div>
  )
};

const Icon = ({ data }) => {
  return <div className={classNames(['option-icon', data.className])}>●</div>;
};

const SingleValue = ({ data, children }) => (
  <div style={{whiteSpace: 'nowrap', width: '16px' }}>
    <Icon data={data}/> {children}
  </div>
);

const Option = ({ data , label, ...props }) => (
  <components.Option {...props}>
    <div style={{ paddingLeft: 16, position: 'relative' }}>
      <div style={{ position: 'absolute', left: -3, top: -3 }}><Icon data={data}/></div> {label}
    </div>
  </components.Option>
);

export const useVenues = (item, options, target) => {
  const urlParams = `?${target || 'item'}=${item.key}`;
  const [processedItem, setProcessedItem] = useState({});
  const [venue, setVenue] = useState(null);
  const { pending, result, error } = useFetch(`/admin/api/venues.json${urlParams}`);

  // Status
  const processed = useMemo(() => (item || {}).key === processedItem.key, [item, processedItem]);

  // Filter options
  const venues = useMemo(() => {
    if (pending || error) return [];
    return result.venues;
  }, [pending, item, processed]);

  // Select option
  useEffect(() => {
    if (!pending && !error && !processed) {
      let newVenue = venue;
      const venueIdQueryParam = new URLSearchParams(location.search).get('venue_id')

      if (!newVenue) {
        let defaultFilterOption = item.defaultVenueFilter;

        if (venueIdQueryParam) {
          defaultFilterOption = venueIdQueryParam;
        } else if (defaultFilterOption == 'all_venues') {
          defaultFilterOption = 'venue-group-all-venues';
        } else if (defaultFilterOption === 'first_venue') {
          defaultFilterOption = venues[0]?.id;
        } else {
          defaultFilterOption = options.venueId
        }

        // We use == because we need to compare values and do not compare types
        const selectedVenue = venues.find(venue => venue.id == defaultFilterOption);

        newVenue = selectedVenue || venues[0];
      }

      if (!(venues.map(x => x.id).includes(newVenue?.id))) {
        newVenue = venues[0];
      }

      setVenue(newVenue);
      setProcessedItem(item)
    }
  }, [pending, processed]);

  return {
    venues,
    venue,
    setVenue,
    error,
    pending: !processed
  }
};
