import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ['category', 'area'];

  connect() {
    $('#search-datepicker').datepicker({
      orientation: "bottom auto",
      maxViewMode: "days",
      format: 'dd/mm/yyyy',
      weekStart: 1,
      autoclose: true
    })
  }

  submit(event) {
    event.preventDefault();

    const category = this.categoryTarget.value;
    const area = this.areaTarget.value;

    try {
      if (category === '') return Helper.flash_message('error', "Please select a facility");
      if (area.trim() === '') return Helper.flash_message('error', "Please enter a location");

      const date = $('#search-datepicker').val();
      const multiVenueId = $('#multi_venue_id').val();
      const multiVenueIdOption = multiVenueId ? `&multi_venue_id=${multiVenueId}` : ''

      window.location = `all-facilities?categories=${category}&area=${area}&date=${date || ''}${multiVenueIdOption}`;
    } catch (error) {
      Helper.flash_message('error', "The browser you are using is not showing any results. Please try again on a different browser or with another device.");
    }
  }
}
