import Swal from "sweetalert2";

export default class NeoDialog {
  static confirm(title, yesText, noText = 'No', showDenyButton = true, options = {}) {
    const { /* add params here */ } = options;

    return Swal.fire({
      title: title,
      showDenyButton: showDenyButton,
      showCancelButton: false,
      confirmButtonText: yesText,
      denyButtonText: noText,
      buttonsStyling: false,
      reverseButtons: true,
      backdrop: true,
      allowOutsideClick: false,
      position: 'center',
      customClass: {
        popup: 'neo-dialog-popup',
        title: 'neo-dialog-title',
        confirmButton: 'btn btn-primary neo-dialog-confirm-button',
        denyButton: 'btn btn-default',
        actions: 'neo-dialog-actions'
      },
    })
  }
}
