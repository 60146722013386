import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import NeoModal from "src/NeoModal";

export default class extends Controller {
  connect() {
    this.navigateToPaymentAction();
  }

  update(e) {
    const url = e.target.dataset.url;

    axios.get(url).then(response => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:payment_success', (e) => {
        $(modal).modal('hide');

        window.loadedTabs = [];
        window.loadOrderTab($("a[data-target='#payments_info']"), true);
        window.reloadOrderHeader();
      })

      modal.addEventListener('neo_modal:failed', (e) => {
        const errors = e.detail.data.errors;
        Helper.flash_message('error', errors.base);
      })
    });
  }

  navigateToPaymentAction() {
    const isMobile = window.innerWidth <= 800;
    const url = new URL(window.location.href);
    const anchor = url.searchParams.get('navigateTo');

    if (anchor === 'process_payment') {
      setTimeout(() => {
        const parentClassName = isMobile ? '.mobile-only' : '.hide-on-mobile ';
        const visibleElement = document.querySelector(`${parentClassName} #process_payment_request`)

        if (visibleElement) {
          const offset = visibleElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: offset,
            behavior: 'smooth'
          });
        }
      }, 500);
    }
  }
}
