import React from "react";

export default ({ index, len2, deleteChildFacility, revertChildFacility, data }) => {
  const { _destroy } = data ;

  if (_destroy) {
    return (
      <button type='button'
              style={{ width: '165px' }}
              className="btn btn-warning"
              onClick={() => revertChildFacility(index)}>
        Revert
     </button>
    )
  } else {
    if (len2 <= 2) return null;

    const btnTitle = data.id === '' ? 'Delete' : (data.ordersCount > 0 ? 'Soft delete' : 'Hard delete');

    return (
      <button type='button'
              data-orders-count={data.ordersCount}
              style={{ width: '165px' }}
              className="btn btn-danger"
              onClick={() => deleteChildFacility(index)}>
        {btnTitle}
      </button>
    )
  }
}