class Facility {
  constructor() { }

  start() {
    if ($('body.facilities_show, body.facilities_standalone').length < 1) {
      return
    }

    if ($('input#is_new_message').val() === 'new') {
      $('a#new_message').click();
    }
  }
}

export default Facility;