import { Controller } from "@hotwired/stimulus"
import NeoDialog from "../src/NeoDialog.js";

export default class CheckoutButtonDisablerController extends Controller {
  static targets = ['button'];

  connect() {
    const self = this;
    const billId = this.element.dataset.billId;
    this.initiator_name = "";
    this.initiator_email = "";

    this.subscription = App.cable.subscriptions.create(
      { channel: "BillStatusChannel", bill_id: billId }, 
      { received: (cmd) => {
        const initiator = cmd.data.initiator;

        this.buttonTargets.forEach((button) => {
          const billTimerInProgress = cmd.data && cmd.data.status === 'in_progress';

          this.initiator_name = initiator?.initiator_name || "";
          this.initiator_email = initiator?.initiator_email || "";
  
          if (cmd.command === 'status' && billTimerInProgress) {
            button.dataset.disabled = true;
            button.classList.add('disabled');
          } else {
            delete button.dataset.disabled;
            button.classList.remove('disabled');
          }
        });
      }
    });
  }

  click(event) {
    if (event.target.dataset.disabled === 'true') {
      event.stopImmediatePropagation();

      const message = `The checkout process is currently being completed by ${this.initiator_name} - ${this.initiator_email}. \
      Please try again later or contact them directly.`;
      NeoDialog.confirm(message, 'Ok', 'No', false);
    }
  };
}
