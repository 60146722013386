import { Controller } from "@hotwired/stimulus"
import axios from 'axios';

class CalendarBookingModalController extends Controller {
  static targets = ['questionsContainer'];

  connect() {
    this.isQuestionVisible = false;
  }

  toggleQuestionsVisibility(event) {
    event.preventDefault();

    if (this.isQuestionVisible) {
      this.hideQuestions(event);
    } else {
      this.loadQuestions(event);
    }
  }

  loadQuestions(event) {
    const target = event.target;
    const container = this.questionsContainerTargets[0];
    const orderId = target.dataset.orderId;

    if (!container) return null;

    axios
      .get(`/admin/calendar/venue_questions`, {
        params: { order_id: orderId },
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      })
      .then((response) => {
        container.innerHTML = response.data;
        target.innerHTML = 'Hide answers to Venue Questions';
        this.isQuestionVisible = true;
      })
      .catch((error) => {
        console.error("Error loading venue questions:", error);
        container.innerHTML = "<p class='text-danger'>Failed to load questions. Please try again.</p>";
      });
  }

  hideQuestions(event) {
    this.questionsContainerTargets.forEach(container => container.innerHTML = '');
    this.isQuestionVisible = false;
    event.target.innerHTML = 'View answers to Venue Questions';
  }
}

export default CalendarBookingModalController;
