import React from "react";
import InlineDatepicker from "src/components/LegacyDatepicker/InlineDatepicker";

export default ({ eventDate, minEventDate, maxEventDate, onChange }) => (
  <div className="form-group string optional event_date vert-offset-bottom-10">
    <label className="string optional control-label">Date</label>

    <InlineDatepicker
      value={eventDate}
      minDate={minEventDate}
      maxDate={maxEventDate}
      onChange={onChange}
    />
  </div>
);
