import BaseController from "../neo_modal_controller";

export default class BillApproveController extends BaseController {
  connect() {
    super.connect();
  }

  sendForm() {
    $(this.element).modal('hide');
  }
}