import React, { useState } from 'react';
import Document from "./Document";
import { v4 as uuidv4 } from 'uuid';

export default ({ uploadUrl,
                  initDocuments,
                  mandatory,
                  disabled,
                  parentElement,
                  allowDelete = true,
                  documentsLimit = 3 }) => {

  const [documents, setDocuments] = useState(initDocuments);

  const addDocument = (e) => {
    e.preventDefault();
    setDocuments([...documents, { uid: uuidv4(), file: null }]);
  }

  const updateFile = (index, file) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index]['file'] = file;
    setDocuments(updatedDocuments);
  }

  const removeDocument = (index) => {
    if (documents.length > 1) {
      const updatedDocuments = documents.filter((_, i) => i !== index);
      setDocuments(updatedDocuments);
    } else {
      const updatedDocuments = [...documents];
      updatedDocuments[index]['file'] = null;
      setDocuments(updatedDocuments);
    }
  }

  const sendEvent = (v) => {
    if (!parentElement) return null;

    let event = new CustomEvent('updateUploadStatus', {
      bubbles: true,
      cancelable: true,
      detail: v
    });

    parentElement.dispatchEvent(event);
  }

  const isMandatory = mandatory && documents.every((x) => x.file === null)

  return (
    <div>
      <div>
        {documents.map((document, index) => (
          <Document
            index={index}
            documentsLength={documents.length}
            key={document.uid}
            uid={document.uid}
            uploadUrl={uploadUrl}
            file={document.file}
            updateFile={(data) => updateFile(index, data)}
            removeDocument={() =>  removeDocument(index)}
            onInProgress={sendEvent}
            allowDelete={allowDelete}
            mandatory={isMandatory}
            disabled={disabled}
          />
        ))}
      </div>

      {documents.length === documentsLimit &&
        <div>The maximum number of documents has been reached</div>
      }

      {documents.length < documentsLimit &&
        <a href="#" onClick={(e) => addDocument(e)}>Add another document</a>
      }
    </div>
  )
}
