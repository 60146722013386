import React, { useState, useContext } from "react";
import axios from "axios";
import history from "../utils/history";
import ConfirmationErrorModal from "./ConfirmationErrorModal";
import { StaffRotaContext } from "../utils/context";
import NeoDialog from "../../../NeoDialog";

export default ({ venueId, serviceMessage }) => {
  const { staffRotaCreated } = useContext(StaffRotaContext);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errors, setErrors] = useState(false);
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);

  const confirmStaffItem = (staffRotaId) => {
    axios
      .post('/admin/staff_rota/confirm.json', { staff_rota_id: staffRotaId })
      .then((response) => {
        history.push(`/admin/staff_rota?venue_id=${venueId}`);
        staffRotaCreated(response.data);
        setTimeout(() => setConfirmationInProgress(false), 150);
      })
      .catch(error => {
        setErrors(error.response.data.errors);
        setShowErrorModal(true);
        setConfirmationInProgress(false);
      });
  };

  const performSubmit = () => {
    if (confirmationInProgress) return true;
    setConfirmationInProgress(true);

    const formData = new FormData(document.getElementById('staff-form'));
    const staffRotaId = formData.get('staff_rota_id');
    
    if (serviceMessage) {
      NeoDialog
        .confirm(serviceMessage, 'Confirm', 'Cancel')
        .then((result) => {
          if (result.isConfirmed) {
            confirmStaffItem(staffRotaId);
          } else {
            setConfirmationInProgress(false);
          }
        })
    } else {
      confirmStaffItem(staffRotaId);
    }
  }

  return (
    <>
      <ConfirmationErrorModal
        errors={errors}
        show={showErrorModal}
        setShow={setShowErrorModal}
      />

      <button
        className="btn btn-lg btn-primary btn-no-radius"
        style={{ width: 250 }}
        type='button'
        disabled={confirmationInProgress}
        onClick={performSubmit}
      >
        Confirm
      </button>
    </>
  )
}