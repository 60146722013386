class Home {
  constructor() { }

  start() {
    $('#user_modal')
      .on('show.bs.modal', () => $('body').css('overflow', 'hidden'))
      .on('hidden.bs.modal', () => $('body').css('overflow', 'auto'));

    if ($(window).width() <= 800) {
      $('.footer-block').click((event) => {
        const $this = $(event.currentTarget);
        const element = $this.next('dd').children();

        element.toggle('slow');
      });
    }
  }
}

export default Home;

window.Helper = {
  flash_message(type, messages, title, duration) {
    if (title == null) { title = "" }
    if (!duration) { duration = 5000 }

    toastr.options = {
      "closeButton": false,
      "debug": false,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "500",
      "timeOut": duration,
      "preventDuplicates": true,
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut",
      'body-output-type': 'trustedHtml'
    }

    const message = (Array.isArray(messages) ? messages.join("<br/>") : messages);
    toastr[type](`<span>${ message }</span>`, title);

    message.replace("<br/>", "\n");
  }
}
