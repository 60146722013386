import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import Neo from "src/Neo";
import NeoModal from "src/NeoModal";

class RelatedTeamsController extends Controller {
  send(e) {
    e.preventDefault();

    const button = e.target;
    const { url, tableName } = button.dataset;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:success', (event) => {
        const data = event.detail.response.data;

        Helper.flash_message('success', data.notice);

        $(modal).modal('hide');

        if (this.isOrderPage) {
          window.reloadOrderHeader();
        } else {
          Neo.reloadTable(tableName);
        }
      })
    });
  }

  delete(e) {
    e.preventDefault();

    const { dataset, attributes } = e.target;

    if (attributes.disabled) return;

    Neo.confirm(dataset.message, () => {
      axios.delete(dataset.url).then((response) => {
        Helper.flash_message('success', response.data.notice)
        Neo.reloadTable(dataset.tableName);
      }).catch((error) => {
        Neo.processError(error, () => {
          Helper.flash_message('error', error.response.data.error)
        })
      })
    })
  }

  toggleHide(e) {
    e.preventDefault();

    const { url, hidden } = e.target.dataset

    axios
      .put(url, { related_team: { hidden: hidden }})
      .then((response) => {
        Helper.flash_message('success', response.data.notice);
        Neo.reloadTable('account_related_teams_table');
      });
  }

  get isOrderPage() {
    return document.getElementById('order_header') !== null;
  }
}

export default RelatedTeamsController;
