import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export default class extends Controller {
  connect() {
    this.swiper = new Swiper('.logo-slider', {
      slidesPerView: 1,
      slidesPerGroup: 1,
      grid: {
        rows: 4,
        fill: 'column', // This replaces slidesPerColumnFill
      },
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        470: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          grid: {
            rows: 4,
          },
        },
        580: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          grid: {
            rows: 4,
          },
        },
        980: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          grid: {
            rows: 4,
          },
        },
        1320: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          grid: {
            rows: 4,
          },
        },
      },
    });
  }
}
