import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class SelectPrimaryUserController extends Controller {
  performAction(event) {
    const dropdown = event.target;

    const selectedIndex = dropdown.selectedIndex;
    const selectedOption = dropdown.options[selectedIndex];

    axios
      .post(`/secondary_users/set_current_primary?primary_id=${selectedOption.value}`)
      .then(() => window.location.reload());
  }
}
