import { Controller } from "@hotwired/stimulus"

class RoomController extends Controller {
  static targets = ['distance'];

  connect() {
    const gallery = this.element.querySelector("ul.smallGallery");

    if (gallery) {
      $(gallery).lightSlider({
        item: 1,
        slideMargin: 0,
        loop: true,
        pager: false
      });
    }
  }
}

export default RoomController;